import { AxiosResponse } from 'axios';
import React, { BaseSyntheticEvent, ChangeEvent, MouseEvent, PureComponent } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { usersActions } from '../../../store/user';
import Loader from '~/components/common/Loader/Loader';
import { modalActions } from '~/store/modal';
import { numbersActions, ICPAASInvertoryNumberForm, NumberDetail, CpaasNumberDetail } from '~/store/number';
import './UserForm.scss';
import { ICustomer } from '~/store/customer';
import { ThemedRadio, ThemedInput, ThemedButton, ThemedSwitch } from '~/components/common/themed';
import { partnersActions } from '~/store/partner';
import { EMAIL_REGEXP } from '~/config';
import { Branding } from '~/branding';

const NUMBER_GET_NEW = 'NUMBER_GET_NEW';
const NUMBER_USE_EXISTING = 'NUMBER_USE_EXISTING';
const NUMBER_USE_BUSINESS = 'NUMBER_USE_BUSINESS';
const NUMBER_CPAAS_INVENTORY = 'NUMBER_CPAAS_INVENTORY';

interface State {
  [x: string]: any;
  numberCode: number | string;
  numberBusiness?: string;
  country: string;
  didType: string;
  businessCountry: string;
  cpaasNumber: string;
  isNumberSelectOpen: boolean;
  isCPAASNumberSelectOpen: boolean,
  shouldPromptForCampaignId: boolean;
  shouldPromptForCPAASCampaignId: boolean;
  isCampaignIdRequired: boolean;
  isCPAASCampaignIdRequired: boolean;
  numberType: string;
  isBusinessTollfreeNumber: boolean,
  numberDetail?: NumberDetail;
  cpaasNumberDetails?: CpaasNumberDetail;
  campaignId: string,
  form: {
    id?: string;
    email: string;
    first_name: string;
    last_name: string;
    title: string;
    show_censor_contact: boolean;
    phone: {
      owner: string;
      phone_number: string;
      timezone: string;
      title: string;
    }
  },
  businessTollfreeInfo: {
    businessName: string;
    contactName: string;
    contactJobTitle: string;
    contactPhone: number | string;
    contactEmail: string;
  }
}

interface StateProps {
  authenticatedUser: any;
  isFetching: boolean;
  isNumbersFetching: boolean;
  isCPAASInventoryNumbersFetching: boolean;
  customer: ICustomer;
  numbers: any[];
  cpaasInventorynumbers: any[];
  phones: any[];
  users: any[];
  user: any;
  errors: string[];
  number_errors: string[];
  partner: any;
}

interface OwnProps {
  userId: string;
}

interface DispatchProps {
  closeModal: () => void;
  createUser: (form: any) => Promise<AxiosResponse>;
  getNumbers: (areaCode: number | undefined, didType: String, country: String) => Promise<AxiosResponse>;
  getCompNumbers: (customerId?: string) => Promise<AxiosResponse>;
  updateUser: (form: any) => Promise<AxiosResponse>;
  numbersClear: () => void;
  getPartner: (partnerId: string) => void;
  getCPAASInventoryNumbers: (number: ICPAASInvertoryNumberForm) => Promise<AxiosResponse>;
}

type Props = DispatchProps & OwnProps & StateProps;

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  createUser: usersActions.createUser,
  getNumbers: numbersActions.getNumbers,
  getCPAASInventoryNumbers: numbersActions.getCPAASInventoryNumbers,
  getCompNumbers: numbersActions.getCompNumbers,
  numbersClear: numbersActions.numbersClear,
  updateUser: usersActions.updateUser,
  getPartner: partnersActions.getPartner,
};

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.auth.authenticatedUser,
  isFetching: state.users.isFetching,
  isNumbersFetching: state.numbers.isFetching,
  isCPAASInventoryNumbersFetching: state.numbers.isCPAASInventoryNumberFetching,
  customer: state.customers.customer,
  numbers: state.numbers.numbers,
  cpaasInventorynumbers: state.numbers.cpaasInventoryNumbers,
  phones: state.numbers.phones,
  users: state.users.users,
  user: state.users.user,
  errors: state.users.errors,
  number_errors: (state.numbers.errors && state.numbers.errors.length > 0) ? state.numbers.errors :
    (state.orders.errors && state.orders.errors.length > 0 ? state.orders.errors : state.numbers.cpaasInventoryErrors),
  partner: state.partners.partner || {}
});

class UserForm extends PureComponent<Props, State> {
  cpaasFetchDetails = {
    pre_num: '',
    fetch: false
  }
  numberSelect: Select | null = null;
  cpaasNumberSelect: Select | null = null;
  excludedNumbers: any[] | null = [];
  state = {
    numberCode: '',
    numberBusiness: '',
    country: 'US',
    didType: 'Mobile & Landline',
    businessCountry: 'US',
    cpaasNumber: '',
    isNumberSelectOpen: false,
    isCPAASNumberSelectOpen: false,
    shouldPromptForCampaignId: false,
    shouldPromptForCPAASCampaignId: false,
    isCampaignIdRequired: false,
    isCPAASCampaignIdRequired: false,
    numberType: NUMBER_GET_NEW,
    isBusinessTollfreeNumber: false,
    campaignId: '',
    form: {
      email: '',
      first_name: '',
      last_name: '',
      title: '',
      show_censor_contact: false,
      phone: {
        owner: '',
        phone_number: '',
        timezone: '',
        title: '',
      }
    },
    businessTollfreeInfo: {
      businessName: '',
      contactName: '',
      contactJobTitle: '',
      contactPhone: '',
      contactEmail: '',
    }
  }

  componentDidMount() {
    this.loadUser();
    this.props.getCompNumbers();
    const { reseller_id } = this.props.customer;
    console.log('reseller_id ', reseller_id);
    if (reseller_id) {
      this.props.getPartner(reseller_id);
    }
  }

  loadUser = () => {
    // If edit existing user - this.props.userId setted, if new user form - undefined
    if (this.props.userId) {
      const { user } = this.props;
      if (user) {
        this.setState({
          numberType: NUMBER_USE_EXISTING,
          form: {
            id: user.id,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            title: user.title,
            show_censor_contact: user.show_censor_contact,
            // phone: user.phones ? user.phones[0] : false
            phone: user.phones ? user.phones.find((phone: { id: any; }) => phone.id === user.default_phone_id) : false
          }
        });
      }
    }
  }

  handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();

    if (this.props.authenticatedUser && this.props.customer) {
      const timezone = 'America/New_York';
      const owner = this.props.authenticatedUser.user_level !== 'customer' ? this.props.customer.id : this.props.authenticatedUser.id;
      const phone_number = this.state.numberType === NUMBER_USE_BUSINESS ? `1${this.state.numberBusiness}` : this.state.form.phone.phone_number;
      const phone = {
        phone_number,
        title: NUMBER_USE_EXISTING ? undefined : `${this.state.form.first_name} ${this.state.form.last_name}`,
        timezone,
        owner
      };
      const cpaas_details = (this.state.numberType === NUMBER_CPAAS_INVENTORY) ? {
        isCPAASInventory: true,
        cpaasInventoryNumbers: this.props.cpaasInventorynumbers.filter((el: CpaasNumberDetail) => this.state.form.phone.phone_number === '1' + el.didNumber).map((el: CpaasNumberDetail) => {
          let details = {
            'did': el.didNumber,
            'mmsEnabled': true,
            'mmsRouteId': this.props.partner.cpaas_detail.mms_key,
            'smsEnabled': true,
            'smsConfigId': this.props.partner.cpaas_detail.sms_key,
            'action': 'Update'
          };
          if (el.didType !== 'Toll Free' && this.state.campaignId) {
            Object.assign(details, {a2p: true, campaignId: this.state.campaignId});
          }
          return details;
        }),
      } : ( (this.state.numberType === NUMBER_GET_NEW) ? {
        isCPAASInventory: false,
        cpaasInventoryNumbers: this.props.numbers.filter((el: NumberDetail) => this.state.form.phone.phone_number === '1' + el.did[0]).map((el: NumberDetail) => {
          let details = {
            'did': el.did[0],
            'mmsEnabled': true,
            'smsEnabled': true,
            'action': 'Update'
          };
          if (el.didType !== 'Toll Free' && this.state.campaignId) {
            Object.assign(details, {a2p: true, campaignId: this.state.campaignId});
          }
          return details;
        }),
      } : {
        isCPAASInventory: false,
        cpaasInventoryNumbers: undefined
      } );
      const form: any = { ...this.state.form,
        username: this.state.form.first_name,
        send_email_invite: !this.props.userId,
        phone,
        numberType: this.state.numberType,
        cpaas_details,
      };
      if (this.props.userId) {
        if (!this.isAuthenticatedForNumberChange()) {
          delete form.phone;
        }
        this.props.updateUser(form).then(this.props.closeModal);
      } else {
        if (this.state.numberType === NUMBER_USE_BUSINESS){
          form.campaignId = !this.state.isBusinessTollfreeNumber ? this.state.campaignId : undefined;
          if (this.state.isBusinessTollfreeNumber && this.state.businessTollfreeInfo) {
            form.smscOrderInfoDTO = this.state.businessTollfreeInfo;
          }
        }
        this.props.createUser(form).then(this.props.closeModal);
      }
      this.props.numbersClear();
    }
  }

  handleRadio = (e: ChangeEvent) => {
    e.stopPropagation();
    const { value } = e.target as HTMLInputElement;
    // Need clear `numbers` because use this array in other tabs
    this.props.numbersClear();
    this.setState({ numberType: value, campaignId: '', form: { ...this.state.form, phone: { ...this.state.form.phone, phone_number: '' } } });
    if (value === NUMBER_USE_EXISTING) {
      this.props.getCompNumbers();
    } else if (value === NUMBER_CPAAS_INVENTORY) {
      //As Per new requirement no need to Eager fetch CpaaS Inventory Numbers.
      // if (this.state.cpaasNumber !== this.cpaasFetchDetails.pre_num) {
      //   this.cpaasFetchDetails.fetch = false;
      // }
      // this.getCPAASNumber(false);
    }
  }

  handleFieldChange = (id: string, value: string) => this.setState({ form: { ...this.state.form, [id]: value } });

  handleStateChange = (id: string, value: string) => this.setState({ [id]: value });
  handleStateChangeNumb = (id: string, value: number) => this.setState({ [id]: value });

  handleFieldChangeCampaignId = (_id: string, value: string) => {
    this.setState({ ...this.state, campaignId: value });
  }

  handleStatePhoneChange = (e: ChangeEvent) => {
    e.stopPropagation();
    const { id, value } = e.target as HTMLInputElement;
    if (/^\d*$/.test(value)) this.setState({ [id]: value });
  }

  handleSelectChange = (value?: { value: any; label: any; }) => {
    const numberDetail = value ? this.props.numbers.find((n: NumberDetail) => (('1' + n.did[0].toString()) === value.value)) : undefined;

    this.setState({
      isNumberSelectOpen: false,
      shouldPromptForCampaignId: (!!numberDetail && ['United States', 'Canada'].includes(numberDetail.country) && (numberDetail.didType !== 'Toll Free')),
      isCampaignIdRequired: (!!numberDetail && (numberDetail.country === 'United States') && (numberDetail.didType !== 'Toll Free')),
      numberDetail: numberDetail,
      form: {
        ...this.state.form,
        phone: { ...this.state.form.phone, phone_number: value && value.value }
      }
    }, () => this.numberSelect && this.numberSelect.select.blur());
  }

  handleExistingSelectChange = (value?: { value: any; label: any; }) => {
    this.setState({
      isNumberSelectOpen: false,
      form: {
        ...this.state.form,
        phone: { ...this.state.form.phone, phone_number: value && value.value }
      }
    }, () => this.numberSelect && this.numberSelect.select.blur());
  }

  handleCPAASSelectChange = (value?: { value: any; label: any; }) => {
    const numberDetail = value ? this.props.cpaasInventorynumbers.find((n: CpaasNumberDetail) => (('1' + n.didNumber.toString()) === value.value)) : undefined;

    this.setState({
      isCPAASNumberSelectOpen: false,
      shouldPromptForCPAASCampaignId: (!!numberDetail && ['United States', 'Canada'].includes(numberDetail.country) && (numberDetail.didType !== 'Toll Free')),
      isCPAASCampaignIdRequired: (!!numberDetail && (numberDetail.country === 'United States') && (numberDetail.didType !== 'Toll Free')),
      cpaasNumberDetail: numberDetail,
      form: {
        ...this.state.form,
        phone: { ...this.state.form.phone, phone_number: value && value.value }
      }
    }, () => this.cpaasNumberSelect && this.cpaasNumberSelect.select.blur());
  }


  handleFocus = () => this.setState({ isNumberSelectOpen: true });

  handleSelectFocus = () => {
    if (this.numberSelect) {
      return !this.state.isNumberSelectOpen
        ? this.numberSelect.select.focus()
        : this.numberSelect.select.blur();
    }
  }

  getNumbers = (e: MouseEvent) => {
    e.stopPropagation();
    const { numberCode, didType, country } = this.state;
    if (typeof numberCode === 'number' || numberCode === '') {
      this.props.getNumbers(numberCode === '' ? undefined : numberCode as unknown as number, didType, country).then(_res => {
        this.setState({ isNumberSelectOpen: true }, this.handleSelectFocus);
      });
    }
  }

  getCPAASNumbers = (e: MouseEvent) => {
    e.stopPropagation();
    this.setState({ ...this.state, form: { ...this.state.form, phone: { ...this.state.form.phone, phone_number: '' } } });
    this.cpaasFetchDetails.fetch = false;
    this.getCPAASNumber(true);
  }

  getCPAASNumber = (open: boolean) => {
    const { cpaasNumber } = this.state;
    if (this.props.partner && this.props.partner.cpaas_detail) {
      if (!this.cpaasFetchDetails.fetch) {
        if (this.props.phones) {
          this.excludedNumbers = this.props.phones.map(phone => phone.phone_number.substring(1));
        }
        const number: ICPAASInvertoryNumberForm = {
          serachNumber: cpaasNumber,
          customerId: this.props.partner.cpaas_detail.customer_key,
          excludedNumbers: this.excludedNumbers
        }
        this.props.getCPAASInventoryNumbers(number).then(_res => {
          this.cpaasFetchDetails.fetch = true;
          this.cpaasFetchDetails.pre_num = cpaasNumber;
          if (open) {
            this.setState({ isCPAASNumberSelectOpen: false }, this.handleCPAASSelectFocus);
          }
        });
      }
    }
  }

  handleCPAASSelectFocus = () => {
    if (this.cpaasNumberSelect) {
      return !this.state.isCPAASNumberSelectOpen
        ? this.cpaasNumberSelect.select.focus()
        : this.cpaasNumberSelect.select.blur();
    }
  }

  handleCPAASFocus = () => this.setState({ isCPAASNumberSelectOpen: true });
  handleCPAASBlur = () => this.setState({ isCPAASNumberSelectOpen: false });

  handleSelectChange1 = (value?: { value: any; label: any; }) => {
    this.setState({
      confrimModal: null,
      isNumberSelectOpen: false,
      form: {
        ...this.state.form,
        phone: { ...this.state.form.phone, phone_number: value && value.value }
      }
    }, () => this.numberSelect && this.numberSelect.select.blur());
  }

  isAuthenticatedForNumberChange = () => this.props.authenticatedUser
    && (this.props.authenticatedUser.user_level === 'administrator'
      || this.props.authenticatedUser.user_level === 'customer_admin');

  handleBusinessTollfreeFieldChangeNumber = (id: string, value: string | number) => {
    this.setState({ businessTollfreeInfo: { ...this.state.businessTollfreeInfo, [id]: value } });
  }

  validateBusinessTollfreeInfo = () => {
    const { businessName, contactName,
      contactJobTitle,
      contactPhone,
      contactEmail } = this.state.businessTollfreeInfo;
    return !businessName || !contactName || !contactJobTitle || !contactPhone || (!contactEmail || !EMAIL_REGEXP.test(contactEmail));
  }

  validateCampaignId = () => {
    if ( (this.state.numberType === NUMBER_GET_NEW && this.state.shouldPromptForCampaignId && (this.state.isCampaignIdRequired || this.state.campaignId))
      || (this.state.numberType === NUMBER_USE_BUSINESS && !this.state.isBusinessTollfreeNumber && (this.state.businessCountry === 'US' || this.state.campaignId))
      || (this.state.numberType === NUMBER_CPAAS_INVENTORY && this.state.shouldPromptForCPAASCampaignId && (this.state.isCPAASCampaignIdRequired || this.state.campaignId)) ) {
      return !/^C[A-Z0-9]{6}$/.test(this.state.campaignId);
    }
    return false;
  }

  render() {
    const options = this.state.numberType === NUMBER_GET_NEW ? this.props.numbers.map(number => ({
      value: '1' + number.did[0],
      label: '1' + number.did[0]
    })) : this.state.numberType === NUMBER_USE_EXISTING ? this.props.phones.map(phone => ({
      value: phone.phone_number,
      label: phone.phone_number
    })) : this.state.numberType === NUMBER_CPAAS_INVENTORY ? this.props.cpaasInventorynumbers.map((number: any) => ({
      value: '1' + number.didNumber,
      label: '1' + number.didNumber
    })) : [];

    const number_errors = this.props.number_errors.length > 0 ? <ul className="error-msg">
      {this.props.number_errors.map((error: string, i: number) => <li key={i}>{error}</li>)}
    </ul> : null;

    const didTypes = [{
      value: 'Mobile & Landline',
      label: 'Mobile & Landline'
    },
    {
      value: 'Toll Free',
      label: 'Toll Free'
    },
    ];

    const countries = [{
      value: 'US',
      label: 'United States'
    }, {
      value: 'CA',
      label: 'Canada'
    }];
    const countryValue = countries.find(countrie => countrie.value === this.state.country);
    const businessCountryValue = countries.find(country => country.value === this.state.businessCountry);
    const didTypeValue = didTypes.find(didType => didType.value === this.state.didType);


    const newAssignNumbers = () => (<React.Fragment>
      <div className="prt-form-cell one-third">
        <ThemedRadio
          checked={this.state.numberType === NUMBER_GET_NEW}
          id='assign_number_new'
          name='assign_number'
          onChange={this.handleRadio}
          text='Get New Number'
          value={'' + NUMBER_GET_NEW}
        />
        <ThemedRadio
          checked={this.state.numberType === NUMBER_USE_EXISTING}
          id='assign_number_existing'
          name='assign_number'
          onChange={this.handleRadio}
          text='Use Existing Number'
          value={'' + NUMBER_USE_EXISTING}
        />
        {this.props.authenticatedUser.user_level !== 'customer_admin' && <ThemedRadio
          checked={this.state.numberType === NUMBER_USE_BUSINESS}
          id='assign_number_business'
          name='assign_number'
          onChange={this.handleRadio}
          text='Use Business Number'
          value={'' + NUMBER_USE_BUSINESS}
        />}
        {this.props.authenticatedUser
          && (this.props.authenticatedUser.user_level === 'administrator'
            || this.props.authenticatedUser.user_level === 'reseller')
          && this.props.partner.cpaas_detail
          && this.props.partner.cpaas_detail.cpaas_enabled
          && <ThemedRadio
            checked={this.state.numberType === NUMBER_CPAAS_INVENTORY}
            id='cpaas_inventory_number'
            name='cpaas_inventory_number'
            onChange={this.handleRadio}
            text='CPaaS Inventory'
            value={'' + NUMBER_CPAAS_INVENTORY}
          />
        }
      </div>
      {this.state.numberType === NUMBER_GET_NEW && <div className="prt-form-cell two-third">
        <div className="prt-form-cell full-width">
          <p className="assignNumbers__description">
            You can request new phone number from {`${Branding.COMPANY_NAME}`}.<br />
            Choose Area Code and get available number.<br />
            This number will be your default number.
          </p>
        </div>
        <div className="prt-form-cell one-half">
          <label className="mandatory" htmlFor="number_type">Number Type</label>
          <Select
            name="number_type"
            className="selector"
            onChange={(didType: any) => {
              this.setState({ didType: didType.value });
            }}
            value={didTypeValue}
            options={didTypes}
          />
        </div>
        <div className="prt-form-cell one-half">
          <label className="mandatory" htmlFor="country">Country</label>
          <Select
            name="country"
            className="selector"
            onChange={(country: any) => {
              this.setState({ country: country.value });
            }}
            value={countryValue}
            options={countries}
          />
        </div>
        <div className="prt-form-cell one-half">
          <ThemedInput
            className="prt-text-field assignNumbers__areaCode"
            id="numberCode"
            onChange={this.handleStateChangeNumb}
            placeholder="Area Code"
            type="text"
            maxLength={3}
            validation="number"
            value={this.state.numberCode}
          />
        </div>
        <div className="prt-form-cell one-half">
          <ThemedButton
            className="assignNumbers__getButton"
            disabled={this.props.isNumbersFetching || (this.state.numberCode !== '' && this.state.numberCode as unknown as number < 99)}
            onClick={this.getNumbers}
            type="button">
            {this.props.isNumbersFetching ? <Loader /> : 'Get Numbers'}
          </ThemedButton>
        </div>
        <div className="prt-form-cell full-width">
          <Select
            name="number"
            options={options}
            className="selector"
            ref={ref => this.numberSelect = ref}
            menuIsOpen={this.state.isNumberSelectOpen}
            onChange={this.handleSelectChange}
            onFocus={this.handleFocus}
            value={{ value: this.state.form.phone.phone_number, label: this.state.form.phone.phone_number }}
          />
          {number_errors}
        </div>
        {this.state.shouldPromptForCampaignId &&
        <div className="prt-form-cell full-width">
          <ThemedInput
            className="prt-text-field assignNumbers__campaignId"
            id="campaignId"
            label="Campaign ID"
            onChange={this.handleFieldChangeCampaignId}
            placeholder="Campaign ID"
            type="text"
            maxLength={7}
            validation="campaign"
            value={this.state.campaignId}
            required={this.state.isCampaignIdRequired}
          />
        </div>}
      </div>}
      {this.state.numberType === NUMBER_USE_EXISTING && <div className="prt-form-cell two-third">
        <p className="assignNumbers__description">
          You can use existing phone number.<br />
          Choose previously created phone number.
        </p>
        <Select
          name="number"
          options={options}
          placeholder="Type phone number or phone number label"
          className="selector"
          onChange={this.handleExistingSelectChange}
        />
        {number_errors}
      </div>}
      {this.state.numberType === NUMBER_USE_BUSINESS &&
        <div className="prt-form-cell two-third">
          <div className="prt-form-cell  full-width">
            <h2 className='prt-group-header assignNumbers__header'>TOLLFREE {`${this.state.isBusinessTollfreeNumber ? 'ENABLE' : 'DISABLE'}`}</h2>
          </div>
          <div className="prt-form-cell one-half">
            <ThemedSwitch
              checkedIcon={<i>ON</i>}
              uncheckedIcon={<i>OFF</i>}
              onColor={"#00bbe5"}
              offColor={"#a1a1a1"}
              onChange={() =>
                this.setState(prevState => ({
                  isBusinessTollfreeNumber: !prevState.isBusinessTollfreeNumber
                }))
              }
              checked={!!this.state.isBusinessTollfreeNumber}
            />
          </div>
          <div className="prt-form-cell one-half">
            <label className="mandatory" htmlFor="businessCountry">Country</label>
            <Select
              name="businessCountry"
              className="selector"
              onChange={(businessCountry: any) => {
                this.setState({ businessCountry: businessCountry.value });
              }}
              value={businessCountryValue}
              options={countries}
            />
          </div>

          <div className="prt-form-cell full-width">
            <p className="assignNumbers__description">Non-mobile number, we recommend the business's usual landline</p>
            <section>
              <img id='phone_number_flag' src={`https://flagicons.lipis.dev/flags/4x3/${this.state.businessCountry.toLowerCase()}.svg`} alt={`${this.state.businessCountry} Flag`} />
              <span>+1</span>
              <input
                id="numberBusiness"
                maxLength={10}
                onChange={this.handleStatePhoneChange}
                placeholder="Phone Number"
                type="text"
                value={this.state.numberBusiness.length == 11 && this.state.numberBusiness.indexOf('1') === 0 ?
                  this.state.numberBusiness.slice(1) : this.state.numberBusiness}
              />
              {number_errors}
            </section>
          </div>

          {this.state.isBusinessTollfreeNumber && <>
            <div className="prt-form-cell one-half">
              <ThemedInput
                className="prt-text-field"
                id="businessName"
                label="Business Name"
                onChange={this.handleBusinessTollfreeFieldChangeNumber}
                placeholder="Business Name"
                type="text"
                value={this.state.businessTollfreeInfo.businessName}
                required
              />
            </div>
            <div className="prt-form-cell one-half">
              <ThemedInput
                className="prt-text-field"
                id="contactName"
                label="Contact Name"
                onChange={this.handleBusinessTollfreeFieldChangeNumber}
                placeholder="Contact Name"
                type="text"
                value={this.state.businessTollfreeInfo.contactName}
                required
              />
            </div>

            <div className="prt-form-cell one-half">
              <ThemedInput
                className="prt-text-field"
                id="contactJobTitle"
                label="Contact Job Title"
                onChange={this.handleBusinessTollfreeFieldChangeNumber}
                placeholder="Contact Job Title"
                type="text"
                value={this.state.businessTollfreeInfo.contactJobTitle}
                required
              />
            </div>
            <div className="prt-form-cell one-half">
              <ThemedInput
                className="prt-text-field"
                id="contactPhone"
                label="Contact Phone"
                onChange={this.handleBusinessTollfreeFieldChangeNumber}
                placeholder="Contact Phone"
                type="text"
                validation="number"
                maxLength={10}
                value={this.state.businessTollfreeInfo.contactPhone}
                required
              />
            </div>

            <div className="prt-form-cell full-width">
              <ThemedInput
                className="prt-text-field"
                id="contactEmail"
                label="Contact Email"
                onChange={this.handleBusinessTollfreeFieldChangeNumber}
                placeholder="Contact Email"
                type="text"
                validation="email"
                value={this.state.businessTollfreeInfo.contactEmail}
                required
              />
            </div> </>
          }
          {!this.state.isBusinessTollfreeNumber && <>
            <div className="prt-form-cell full-width">
              <ThemedInput
                className="prt-text-field assignNumbers__campaignId"
                id="businessCampaignId"
                label="Campaign ID"
                onChange={this.handleFieldChangeCampaignId}
                placeholder="Campaign ID"
                type="text"
                maxLength={7}
                validation="campaign"
                value={this.state.campaignId}
                required={this.state.businessCountry === 'US'}
              />
            </div>
          </>
          }

        </div>
      }

      {this.state.numberType === NUMBER_CPAAS_INVENTORY && <div className="prt-form-cell two-third">
        <div className="prt-form-cell full-width">
          <p className="assignNumbers__description">
            You can request Active phone number from {`${Branding.COMPANY_NAME}`}.<br />
            Choose Number and get active number.<br />
            This number will be your default number.
          </p>
        </div>
        <div className="prt-form-cell one-half">
          <ThemedInput
            className="prt-text-field assignNumbers__areaCode"
            id="cpaasNumber"
            onChange={this.handleStateChangeNumb}
            placeholder="Number"
            type="text"
            maxLength={10}
            validation="number"
            value={this.state.cpaasNumber}
          />
        </div>
        <div className="prt-form-cell one-half">
          <ThemedButton
            className="assignNumbers__getButton"
            disabled={this.props.isCPAASInventoryNumbersFetching}
            onClick={this.getCPAASNumbers}
            type="button">
            {this.props.isCPAASInventoryNumbersFetching ? <Loader /> : 'Get Numbers'}
          </ThemedButton>
        </div>
        <div className="prt-form-cell full-width">
          <Select
            className='selector'
            id="cpaasNumbers"
            name="cpaasNumbers"
            onChange={this.handleCPAASSelectChange}
            value={{ value: this.state.form.phone.phone_number, label: this.state.form.phone.phone_number }}
            ref={ref => this.cpaasNumberSelect = ref}
            menuIsOpen={this.state.isCPAASNumberSelectOpen}
            onFocus={this.handleCPAASFocus}
            onBlur={this.handleCPAASBlur}
            options={options}
          />
          {number_errors}
        </div>
        {this.state.shouldPromptForCPAASCampaignId && <>
          <div className="prt-form-cell full-width">
            <ThemedInput
              className="prt-text-field assignNumbers__campaignId"
              id="cpaasCampaignId"
              label="Campaign ID"
              onChange={this.handleFieldChangeCampaignId}
              placeholder="Campaign ID"
              type="text"
              maxLength={7}
              validation="campaign"
              value={this.state.campaignId}
              required={this.state.isCPAASCampaignIdRequired}
            />
          </div>
        </>}
      </div>}

    </React.Fragment>);

    const handleNumberMenu = () => {
      if (this.isAuthenticatedForNumberChange()) {
        return <Select
          id="default_number"
          name="default_number"
          placeholder="Default Number"
          className="selector"
          // disabled={true}
          value={{ value: this.state.form.phone.phone_number, label: this.state.form.phone.phone_number }}
          options={options}
          onChange={this.handleSelectChange1}
          onFocus={this.handleFocus}
        />
      } else {
        return <Select
          id="default_number"
          name="default_number"
          placeholder="Default Number"
          className="selector"
          disabled={true}
          value={{ value: this.state.form.phone.phone_number, label: this.state.form.phone.phone_number }}
        />
      }
    }

    const editAssignNumbers = () => (<React.Fragment>
      {/* <div className="prt-form-cell two-third">
        <label htmlFor="numberCode">Select Existing Number</label>
        <input
          className="prt-text-field"
          id="numberCode"
          onChange={this.handleFieldChangeNumber}
          placeholder="Area Code"
          type="text"
          maxLength={3}
          value={this.state.numberCode}
        />

        <label htmlFor="numberCode">Area Code (NPA)</label>
        <input
          className="prt-text-field assignNumbers__areaCode"
          id="numberCode"
          onChange={this.handleFieldChangeNumber}
          placeholder="Area Code"
          type="text"
          maxLength={3}
          value={this.state.numberCode}
        />
        <button
          className="assignNumbers__getButton"
          disabled={!this.state.numberCode}
          onClick={this.getNumbers}
          type="button">{ this.props.isNumbersFetching
            ? <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            : 'Get Numbers'
          }</button>
        <Select
          name="number"
          options={options}
          ref={ref => this.numberSelect = ref}
          menuIsOpen={this.state.isNumberSelectOpen}
          className="selector"
        />
      </div> */}
      <div className="prt-form-cell full-width">
        <label htmlFor="default_number">Default Number</label>
        {handleNumberMenu()}
        {/* <Select
          id="default_number"
          name="default_number"
          placeholder="Default Number"
          className="selector"
          disabled={true}
          value={{ value: this.state.form.phone.phone_number, label: this.state.form.phone.phone_number }}
        /> */}
      </div>
    </React.Fragment>);

    return (
      <form className="UserForm" onSubmit={this.handleSubmit} autoComplete='off'>
        <div className="prt-form-field">
          <div className="prt-form-cell two-third">
            <ThemedInput
              autoFocus
              id="email"
              label="E-mail"
              onChange={this.handleFieldChange}
              placeholder="E-mail"
              required
              type="email"
              validation="email"
              value={this.state.form.email}
            />
          </div>
          <div className="prt-form-cell one-third"></div>
        </div>

        <div className="prt-form-field">
          <div className="prt-form-cell one-third">
            <ThemedInput
              id="first_name"
              label="First Name"
              onChange={this.handleFieldChange}
              placeholder="First Name"
              required
              type="text"
              validation="required"
              value={this.state.form.first_name}
            />
          </div>
          <div className="prt-form-cell one-third">
            <ThemedInput
              id="last_name"
              label="Last Name"
              onChange={this.handleFieldChange}
              placeholder="Last Name"
              required
              type="text"
              validation="required"
              value={this.state.form.last_name}
            />
          </div>
          <div className="prt-form-cell one-third">
            <ThemedInput
              id="title"
              label="Title"
              onChange={this.handleFieldChange}
              placeholder="Title"
              type="text"
              value={this.state.form.title}
            />
          </div>
        </div>

        {this.props.authenticatedUser
          && (this.props.authenticatedUser.user_level === 'administrator'
          )
          && <div className="prt-form-field">
            <div className="prt-form-cell full-width">
              <h2 className='prt-group-header assignNumbers__header'>Censor Contact</h2>
            </div>
          </div>}

        {this.props.authenticatedUser
          && (this.props.authenticatedUser.user_level === 'administrator'
          )
          && <div className="prt-form-field">
            <div className="prt-form-cell two-third">
              <ThemedSwitch
                checkedIcon={<i>ON</i>}
                uncheckedIcon={<i>OFF</i>}
                onColor={"#00bbe5"}
                offColor={"#a1a1a1"}
                onChange={() =>
                  this.setState(prevState => ({
                    form: { ...this.state.form, show_censor_contact: !prevState.form.show_censor_contact }
                  }))
                }
                checked={!!this.state.form.show_censor_contact}
              />
            </div>
          </div>}

        <div className="prt-form-field">
          <div className="prt-form-cell full-width">
            <h2 className='prt-group-header assignNumbers__header'>Assign Numbers</h2>
          </div>
        </div>
        <div className="prt-form-field">
          {this.props.userId
            ? editAssignNumbers()
            : newAssignNumbers()}
        </div>

        <ul className="error-msg">
          {this.props.errors && this.props.errors.map((error: string, i: number) => <li key={i}>{error}</li>)}
        </ul>

        <div className="prt-form-field">
          <ThemedButton
            className="btn-primary"
            disabled={this.props.isFetching
              || this.validateCampaignId()
              || !EMAIL_REGEXP.test(this.state.form.email)
              || !this.state.form.first_name.length
              || !this.state.form.last_name.length
              || (!this.props.userId && (
                (this.state.numberType === NUMBER_USE_BUSINESS &&
                  (this.state.numberBusiness.length !== 10 || (this.state.isBusinessTollfreeNumber && this.validateBusinessTollfreeInfo())))
                || (this.state.numberType !== NUMBER_USE_BUSINESS &&
                  (!this.state.form.phone.phone_number || this.state.form.phone.phone_number.length !== 11)
                )
              )
              )}
            type="submit">{this.props.isFetching ? <Loader /> : this.props.userId ? 'Update' : 'Add'}</ThemedButton>
          <button className="btn-secondary is-cancel" type="button" onClick={this.props.closeModal}>Cancel</button>
        </div>
      </form>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm as any);
