import React, {PureComponent} from 'react';
import { connect } from 'react-redux';


interface StateProps {
  wl: any;
}
interface DispatchProps {

}
interface OwnProps {
  className?: string;
  disabled?: boolean;
  onClick?: any;
  type?: "button" | "submit" | "reset";
  children: any;
}

export type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: any) => ({
  wl: state.users.wl
});

class ThemedButton extends PureComponent<Props> {
  render() {
    const brandColor = (this.props.wl && this.props.wl.primary_css_color) ? this.props.wl.primary_css_color : false;
    return (
      <button
        className={this.props.className || 'button is-primary'}
        disabled={this.props.disabled || false}
        style={brandColor ? (this.props.className==='assignNumbers__getButton' ? {color: brandColor} : {backgroundColor: brandColor}) : {}}
        type={this.props.type || 'button'}
        onClick={this.props.onClick}>{this.props.children}</button>
    );
  }
}

export default connect(mapStateToProps)(ThemedButton);
